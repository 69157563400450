
import Scrollbar from '../node_modules/smooth-scrollbar';
import Options from 'smooth-scrollbar/options.js';
import Plugins from 'smooth-scrollbar/plugin';
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll'


const s = Scrollbar.init(document.querySelector('#scrollbar'))
const body = document.body;
const scrollbar = document.querySelector(".scroll-content");
const nav = document.querySelector(".header-inner");
const scrollUp = "scroll-up";
const scrollDown = "scroll-down";
let lastScroll = 0;

s.addListener(function (status) {
    const currentScroll = status.offset.y;
    if (currentScroll <= 0) {
        body.classList.remove(scrollUp);
        body.classList.remove(scrollDown);
        return;
    }

    if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
        // down
        body.classList.remove(scrollUp);
        body.classList.add(scrollDown);
    } else if (
        currentScroll < lastScroll &&
        body.classList.contains(scrollDown)
    ) {
        // up
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
    }

    lastScroll = currentScroll;
});
//SITE ANCHORS//
document.getElementById("help_cta1").addEventListener('click', (e) => {
    const scrollbar = Scrollbar.init(document.querySelector('#scrollbar'))
    scrollbar.scrollIntoView(document.querySelector('#donate'), {
        offsetLeft: 34,
        offsetBottom: 0,
        alignToTop: true,
        onlyScrollIfNeeded: true,
      });
    console.log('Scrolling down is working!')
});
document.getElementById("help_cta2").addEventListener('click', (e) => {
    const scrollbar = Scrollbar.init(document.querySelector('#scrollbar'))
    scrollbar.scrollIntoView(document.querySelector('#donate'), {
        offsetLeft: 34,
        offsetBottom: 0,
        alignToTop: true,
        onlyScrollIfNeeded: true,
      });
    console.log('Scrolling down is working!')
});

//END SITE ANCHORS//
////REFRESH PAGE SETTINGS////
window.history.replaceState({}, document.title, "/" + "/");
/////CAROUSEL///


